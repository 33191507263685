import { call, takeEvery } from "redux-saga/effects"
import {
  add_am_ticker_action_done,
  add_contact_ticker_action_done,
  add_membre_ticker_action_done,
  add_ticker_action_done,
  allocation_ticker_action_done,
  am_ticker_action_done,
  contact_ticker_action_done,
  date_am_ticker_action_done,
  depense_ticker_action_done,
  edit_am_ticker_action_done,
  edit_contact_ticker_action_done,
  edit_membre_ticker_action_done,
  info_ticker_action_done,
  liste_am_action_done,
  liste_bu_action_done,
  liste_categories_action_done,
  liste_client_action_done,
  liste_lettre_ticker_action_done,
  liste_marque_action_done,
  liste_marque_search_action_done,
  liste_statuts_tickers_action_done,
  liste_tickers_action_done,
  liste_tickers_alertes_action_done,
  liste_tickers_search_action_done,
  liste_tickers_search_value_action_done,
  liste_tickers_super_user_action_done,
  liste_type_categories_action_done,
  liste_user_team_action_done,
  liste_users_action_done,
  membre_ticker_action_done,
  mes_tickers_15j_action_done,
  mes_tickers_15j_stat_action_done,
  mes_tickers_1mois_action_done,
  mes_tickers_marques_action_done,
  mes_tickers_passif_action_done,
  mes_tickers_sans_activite_action_done,
  send_message_retard_action_done,
  ticker_date_controller_action_done,
  ticker_user_stat_action_done,
  update_ticker_action_done,
  year_info_ticker_action_done
} from "../actions/ticker"
import urls from "../services/api"
import { callApi } from "../services/Callapi"
import { get, patch, post } from "../services/request"


// liste_tickers
function* liste_tickers_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_getTickers}?page=${action.payload.page}${action.payload.textRecherche ? "&search=" + action.payload.textRecherche : ""}${action.payload.textBu ? "&bu=" + action.payload.textBu : ""}${action.payload.TriFilter ? "&tri=" + action.payload.TriFilter : ""}${action.payload.CategorieFilter ? "&categorie=" + action.payload.CategorieFilter : ""}${action.payload.TypeCategorieFilter ? "&typecategorie=" + action.payload.TypeCategorieFilter : ""}${action.payload.AccountManagerFilter ? "&am=" + action.payload.AccountManagerFilter : ""}${action.payload.MarqueFilter ? "&marque=" + action.payload.MarqueFilter : ""}${action.payload.ActifFilter ? "&actif=True" : ""}${action.payload.PassifFilter ? "&passif=True" : ""}${action.payload.ArchivedFilter ? "&archive=True" : ""}${action.payload.AbandonneFilter ? "&abandonne=True" : ""}${action.payload.collaborateurFilter ? "&collaborateur=" + action.payload.collaborateurFilter : ""}`,
    get,
    null,
    liste_tickers_action_done
  )
}

export function* watch_liste_tickers_saga() {
  yield takeEvery("LISTE_TICKER", liste_tickers_saga)
}


// mes_tickers_15j_stat
function* mes_tickers_15j_stat_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_getTickers}?page=${action.payload.page}${action.payload.textRecherche ? "&search=" + action.payload.textRecherche : ""}${"&superuser=True"}${"&activite=1"}${"&actif=True"}${"&am=" + action.payload.id}`,
    get,
    null,
    mes_tickers_15j_stat_action_done
  )
}

export function* watch_mes_tickers_15j_stat_saga() {
  yield takeEvery("MES_TICKER_15J_STAT", mes_tickers_15j_stat_saga)
}


// mes_tickers_15j
function* mes_tickers_15j_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_getTickers}?page=${action.payload.page}${action.payload.textRecherche ? "&search=" + action.payload.textRecherche : ""}${"&activite=1"}${"&actif=True"}${"&am=" + action.payload.id}`,
    get,
    null,
    mes_tickers_15j_action_done
  )
}

export function* watch_mes_tickers_15j_saga() {
  yield takeEvery("MES_TICKER_15J", mes_tickers_15j_saga)
}


// mes_tickers_1mois
function* mes_tickers_1mois_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_getTickers}?page=${action.payload.page}${action.payload.textRecherche ? "&search=" + action.payload.textRecherche : ""}${"&activite=2"}${"&actif=True"}${"&am=" + action.payload.id}`,
    get,
    null,
    mes_tickers_1mois_action_done
  )
}

export function* watch_mes_tickers_1mois_saga() {
  yield takeEvery("MES_TICKER_1MOIS", mes_tickers_1mois_saga)
}


// mes_tickers_sans_activite
function* mes_tickers_sans_activite_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_getTickers}?page=${action.payload.page}${action.payload.textRecherche ? "&search=" + action.payload.textRecherche : ""}${"&activite=3"}${"&actif=True"}${"&am=" + action.payload.id}`,
    get,
    null,
    mes_tickers_sans_activite_action_done
  )
}

export function* watch_mes_tickers_sans_activite_saga() {
  yield takeEvery("MES_TICKER_SANS_ACTIVITE", mes_tickers_sans_activite_saga)
}


// mes_tickers_passif
function* mes_tickers_passif_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_getTickers}?page=${action.payload.page}${action.payload.textRecherche ? "&search=" + action.payload.textRecherche : ""}${"&passif=True"}${"&am=" + action.payload.id}`,
    get,
    null,
    mes_tickers_passif_action_done
  )
}

export function* watch_mes_tickers_passif_saga() {
  yield takeEvery("MES_TICKER_PASSIF", mes_tickers_passif_saga)
}


// mes_tickers_marques
function* mes_tickers_marques_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_getTickers}?page=${action.payload.page}${action.payload.textRecherche ? "&search=" + action.payload.textRecherche : ""}${"&tri=marque"}${"&actif=True"}${"&am=" + action.payload.id}`,
    get,
    null,
    mes_tickers_marques_action_done
  )
}

export function* watch_mes_tickers_marques_saga() {
  yield takeEvery("MES_TICKER_MARQUE", mes_tickers_marques_saga)
}

// liste_tickers_super_user
function* liste_tickers_super_user_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_getTickers}?page=${action.payload.page}&superuser=True&exclude=True${action.payload.textRecherche ? "&search=" + action.payload.textRecherche : ""}${action.payload.textBu ? "&bu=" + action.payload.textBu : ""}${action.payload.TriFilter ? "&tri=" + action.payload.TriFilter : "&tri=60"}${action.payload.k ? "&k=" + action.payload.k : ""}${action.payload.type ? "&type=" + action.payload.type : ""}${action.payload.CategorieFilter ? "&categorie=" + action.payload.CategorieFilter : ""}${action.payload.TypeCategorieFilter ? "&typecategorie=" + action.payload.TypeCategorieFilter : ""}${action.payload.AccountManagerFilter ? "&am=" + action.payload.AccountManagerFilter : ""}${action.payload.MarqueFilter ? "&marque=" + action.payload.MarqueFilter : ""}${action.payload.ActifFilter ? "&actif=True" : ""}${action.payload.PassifFilter ? "&passif=True" : ""}${action.payload.ArchivedFilter ? "&archive=True" : ""}${action.payload.AbandonneFilter ? "&abandonne=True" : ""}${action.payload.collaborateurFilter ? "&collaborateur=" + action.payload.collaborateurFilter : ""}`,
    get,
    null,
    liste_tickers_super_user_action_done
  )
}

export function* watch_liste_tickers_super_user_saga() {
  yield takeEvery("LISTE_TICKER_SUPER_USER", liste_tickers_super_user_saga)
}

// liste_tickers
function* liste_tickers_search_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_getTickers}no-pagination/?actif=True`,
    get,
    null,
    liste_tickers_search_action_done
  )
}

export function* watch_liste_tickers_search_saga() {
  yield takeEvery("LISTE_TICKER_SEARCH", liste_tickers_search_saga)
}

// liste_tickers_search
function* liste_tickers_search_value_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_getTickers}no-pagination/?actif=True${action.payload && "&search=" + action.payload}`,
    get,
    null,
    liste_tickers_search_value_action_done
  )
}

export function* watch_liste_tickers_search_value_saga() {
  yield takeEvery("LISTE_TICKER_SEARCH_VALUE", liste_tickers_search_value_saga)
}


// detail_ticker
function* info_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETTICKER}${action.payload}/`,
    get,
    null,
    info_ticker_action_done
  )
}

export function* watch_info_ticker_saga() {
  yield takeEvery("INFO_TICKER", info_ticker_saga)
}


// year_info_ticker
function* year_info_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETTICKERYEARINFO}${action.payload}/`,
    get,
    null,
    year_info_ticker_action_done
  )
}

export function* watch_year_info_ticker_saga() {
  yield takeEvery("YEAR_INFO_TICKER", year_info_ticker_saga)
}


// add_ticker
function* add_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CREATETICKER}`,
    post,
    action.payload,
    add_ticker_action_done
  )
}

export function* watch_add_ticker_saga() {
  yield takeEvery("ADD_TICKER", add_ticker_saga)
}


// update_ticker
function* update_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_UPDATETICKER}${action.payload.id}/`,
    patch,
    action.payload.data,
    update_ticker_action_done
  )
}

export function* watch_update_ticker_saga() {
  yield takeEvery("UPDATE_TICKER", update_ticker_saga)
}


// contact_ticker
function* contact_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONTACTTICKER}${action.payload}/`,
    get,
    null,
    contact_ticker_action_done
  )
}

export function* watch_contact_ticker_saga() {
  yield takeEvery("CONTACT_TICKER", contact_ticker_saga)
}


// add_contact_ticker
function* add_contact_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONTACTTICKER}`,
    post,
    action.payload,
    add_contact_ticker_action_done
  )
}

export function* watch_add_contact_ticker_saga() {
  yield takeEvery("ADD_CONTACT_TICKER", add_contact_ticker_saga)
}


// edit_contact_ticker
function* edit_contact_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONTACTTICKER}${action.payload.id}/`,
    patch,
    action.payload.data,
    edit_contact_ticker_action_done
  )
}

export function* watch_edit_contact_ticker_saga() {
  yield takeEvery("EDIT_CONTACT_TICKER", edit_contact_ticker_saga)
}


// am_ticker
function* am_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETAM}${action.payload}/`,
    get,
    null,
    am_ticker_action_done
  )
}

export function* watch_am_ticker_saga() {
  yield takeEvery("AM_TICKER", am_ticker_saga)
}


// date_am_ticker
function* date_am_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETAMDAY}?ticker=${action.payload}`,
    get,
    null,
    date_am_ticker_action_done
  )
}

export function* watch_date_am_ticker_saga() {
  yield takeEvery("DATE_AM_TICKER", date_am_ticker_saga)
}


// membre_ticker
function* membre_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETTEAM}${action.payload}/`,
    get,
    null,
    membre_ticker_action_done
  )
}

export function* watch_membre_ticker_saga() {
  yield takeEvery("MEMBRE_TICKER", membre_ticker_saga)
}


// add_am_ticker
function* add_am_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETAM}`,
    post,
    action.payload,
    add_am_ticker_action_done
  )
}

export function* watch_add_am_ticker_saga() {
  yield takeEvery("ADD_AM_TICKER", add_am_ticker_saga)
}


// add_membre_ticker
function* add_membre_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETTEAM}`,
    post,
    action.payload,
    add_membre_ticker_action_done
  )
}

export function* watch_add_membre_ticker_saga() {
  yield takeEvery("ADD_MEMBRE_TICKER", add_membre_ticker_saga)
}


// edit_membre_ticker
function* edit_membre_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETTEAM}${action.payload.id}/`,
    patch,
    action.payload.data,
    edit_membre_ticker_action_done
  )
}

export function* watch_edit_membre_ticker_saga() {
  yield takeEvery("EDIT_MEMBRE_TICKER", edit_membre_ticker_saga)
}


// edit_am_ticker
function* edit_am_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETAM}${action.payload.id}/`,
    patch,
    action.payload.data,
    edit_am_ticker_action_done
  )
}

export function* watch_edit_am_ticker_saga() {
  yield takeEvery("EDIT_AM_TICKER", edit_am_ticker_saga)
}


// depense_ticker
function* depense_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_DEPENSES}?ticker=${action.payload.id}&date=${action.payload.date}`,
    get,
    action.payload.id,
    depense_ticker_action_done,
    true
  )
}

export function* watch_depense_ticker_saga() {
  yield takeEvery("DEPENSE_TICKER", depense_ticker_saga)
}


// allocation_ticker
function* allocation_ticker_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_LISTEALLOCATIONS}?detail=True&ticker=${action.payload.id}&date=${action.payload.date}`,
    get,
    action.payload.id,
    allocation_ticker_action_done,
    true
  )
}

export function* watch_allocation_ticker_saga() {
  yield takeEvery("ALLOCATION_TICKER", allocation_ticker_saga)
}


// liste_tickers_alertes
function* liste_tickers_alertes_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_getTickersAlert}?page=${action.payload.page}${action.payload.textRecherche ? "&search=" + action.payload.textRecherche : ""}${action.payload.textBu ? "&bu=" + action.payload.textBu : ""}${action.payload.TriFilter ? "&tri=" + action.payload.TriFilter : ""}${action.payload.CategorieFilter ? "&categorie=" + action.payload.CategorieFilter : ""}${action.payload.TypeCategorieFilter ? "&typecategorie=" + action.payload.TypeCategorieFilter : ""}${action.payload.AccountManagerFilter ? "&am=" + action.payload.AccountManagerFilter : ""}${action.payload.MarqueFilter ? "&marque=" + action.payload.MarqueFilter : ""}${action.payload.ActifFilter ? "&actif=True" : ""}${action.payload.PassifFilte ? "&passif=True" : ""}${action.payload.ArchivedFilter ? "&archive=True" : ""}${action.payload.AbandonneFilter ? "&abandonne=True" : ""}${action.payload.collaborateurFilter ? "&collaborateur=" + action.payload.collaborateurFilter : ""}`,
    get,
    null,
    liste_tickers_alertes_action_done
  )
}

export function* watch_liste_tickers_alertes_saga() {
  yield takeEvery("LISTE_TICKER_ALERTES", liste_tickers_alertes_saga)
}


// liste_statuts_tickers
function* liste_statuts_tickers_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_STATUT_TICKER}?${action.payload.oldS ? "&old_status=" + action.payload.oldS : ""}${action.payload.newS ? "&new_status=" + action.payload.newS : ""}`,
    get,
    null,
    liste_statuts_tickers_action_done
  )
}

export function* watch_liste_statuts_tickers_saga() {
  yield takeEvery("LISTE_STATUTS_TICKER", liste_statuts_tickers_saga)
}


// liste_categories
function* liste_categories_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETBU}`,
    get,
    null,
    liste_categories_action_done
  )
}

export function* watch_liste_categories_saga() {
  yield takeEvery("LISTE_CATEGORIE", liste_categories_saga)
}


// liste_type_categories
function* liste_type_categories_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETCATEGTYPE}`,
    get,
    null,
    liste_type_categories_action_done
  )
}

export function* watch_liste_type_categories_saga() {
  yield takeEvery("LISTE_TYPE_CATEGORIE", liste_type_categories_saga)
}


// liste_users
function* liste_users_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETUSERS}`,
    get,
    null,
    liste_users_action_done
  )
}

export function* watch_liste_users_saga() {
  yield takeEvery("LISTE_USERS", liste_users_saga)
}


// liste_am
function* liste_am_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETUSERS}?am=true`,
    get,
    null,
    liste_am_action_done
  )
}

export function* watch_liste_am_saga() {
  yield takeEvery("LISTE_AM", liste_am_saga)
}


// liste_user_team
function* liste_user_team_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETUSERS}${action.payload ? "?team=" + action.payload : ""}`,
    get,
    null,
    liste_user_team_action_done
  )
}

export function* watch_liste_user_team_saga() {
  yield takeEvery("LISTE_USER_TEAM", liste_user_team_saga)
}


// liste_marque_search
function* liste_marque_search_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETLISTEMARQUE}?search=${action.payload}`,
    get,
    null,
    liste_marque_search_action_done
  )
}

export function* watch_liste_marque_search_saga() {
  yield takeEvery("LISTE_MARQUE_SEARCH", liste_marque_search_saga)
}


// liste_marques
function* liste_marque_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETLISTEMARQUE}`,
    get,
    null,
    liste_marque_action_done
  )
}

export function* watch_liste_marque_saga() {
  yield takeEvery("LISTE_MARQUE", liste_marque_saga)
}


// liste_clients
function* liste_client_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETCLIENTS}all/`,
    get,
    null,
    liste_client_action_done
  )
}

export function* watch_liste_client_saga() {
  yield takeEvery("LISTE_CLIENT", liste_client_saga)
}


// liste_bus
function* liste_bu_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETBU}`,
    get,
    null,
    liste_bu_action_done
  )
}

export function* watch_liste_bu_saga() {
  yield takeEvery("LISTE_BU", liste_bu_saga)
}


// ticker_user_stat
function* ticker_user_stat_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETSTATSBYWEEK}?time=${action.payload.type}&date=${action.payload.date}&ticker=${action.payload.id}${action.payload.displayerTeam && action.payload.displayerTeam.length > 0 ? "&team=[" + action.payload.displayerTeam + "]" : ""}`,
    get,
    null,
    ticker_user_stat_action_done
  )
}

export function* watch_ticker_user_stat_saga() {
  yield takeEvery("TICKER_USER_STAT", ticker_user_stat_saga)
}


// ticker_date_controller
function* ticker_date_controller_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETSTATSCONTROLS}?time=${action.payload.type}&date=${action.payload.date}&ticker=${action.payload.id}`,
    get,
    null,
    ticker_date_controller_action_done
  )
}

export function* watch_ticker_date_controller_saga() {
  yield takeEvery("TICKER_DATE_CONTROLLER", ticker_date_controller_saga)
}


// liste_lettre_ticker
function* liste_lettre_ticker_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETLETTERTICKER}`,
    get,
    null,
    liste_lettre_ticker_action_done
  )
}

export function* watch_liste_lettre_ticker_saga() {
  yield takeEvery("LETTRE_TICKER", liste_lettre_ticker_saga)
}


// send_message_retard
function* send_message_retard_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_SEND_MESSAGE_REMPL}`,
    post,
    action.payload,
    send_message_retard_action_done
  )
}

export function* watch_send_message_retard_saga() {
  yield takeEvery("SEND_MESSAGE_RETARD", send_message_retard_saga)
}
